"use client";

import LZString from "lz-string";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useEffect,
  useState,
} from "react";

import { CookieKeys } from "../enums/CookieKeys.enum";
import { LoginResponse, useUserGetUserProfile } from "../hooks/api/generated";
import { setCookieUtil, getCookieUtil } from "../utils/cookies";

export interface IUserDateContext {
  data?: LoginResponse;
  isAuthenticated?: boolean;
}

export const AuthContext = createContext<IUserDateContext | undefined>(
  undefined
);
export const UpdateAuthContext = createContext<
  Dispatch<SetStateAction<IUserDateContext | undefined>> | undefined
>(undefined);

export interface IAuthProviderProps {
  children: ReactNode;
}

export function AuthProvider({
  children,
}: Readonly<IAuthProviderProps>): JSX.Element {
  const [user, setUser] = useState<IUserDateContext | undefined>(undefined);
  const { mutateAsync: getUserProfile } = useUserGetUserProfile();

  const updateUserData = useCallback(async (): Promise<void> => {
    const accessToken = getCookieUtil(CookieKeys.AccessToken);
    if (!accessToken) {
      setUser({ data: undefined, isAuthenticated: false });
      return;
    }
    try {
      const userUpdatedData = await getUserProfile();
      setUser({ data: userUpdatedData, isAuthenticated: !!userUpdatedData });
      setCookieUtil({
        key: CookieKeys.UserData,
        value: LZString.compressToBase64(JSON.stringify(userUpdatedData)),
      });
    } catch (error) {
      setUser({ data: undefined, isAuthenticated: false });
    }
  }, [getUserProfile]);

  useEffect(() => {
    updateUserData();
  }, [updateUserData]);

  useEffect((): (() => void) | undefined => {
    if (typeof window !== "undefined") {
      const handleStorageChange = (event: StorageEvent): void => {
        if (event.key === CookieKeys.UserData) {
          window.close();
        }
      };

      window.addEventListener("storage", handleStorageChange);

      return () => {
        window.removeEventListener("storage", handleStorageChange);
      };
    }
    return () => undefined;
  }, []);

  return (
    <AuthContext.Provider value={user}>
      <UpdateAuthContext.Provider value={setUser}>
        {children}
      </UpdateAuthContext.Provider>
    </AuthContext.Provider>
  );
}
