"use client";

import { useEffect, useState } from "react";
import { Button, Link } from "ui-components";

import { getLocalStorage, setLocalStorage } from "@/utils/localStorage";

export default function CookieBanner(): JSX.Element {
  const [cookieConsent, setCookieConsent] = useState<string | boolean>(true);

  useEffect(() => {
    const storedCookieConsent = getLocalStorage("cookie_consent", null);
    setCookieConsent(storedCookieConsent);
  }, [setCookieConsent]);

  useEffect(() => {
    setLocalStorage("cookie_consent", cookieConsent);
  }, [cookieConsent]);

  return (
    <div
      className={`${
        cookieConsent === true
          ? "hidden"
          : "flex flex-col fixed inset-x-0 bottom-0 z-20  justify-center gap-x-8 gap-y-4 bg-[#5d6a77] p-6 ring-1 ring-[#5d6a77] md:flex-row md:items-center lg:px-8 xs:block"
      }`}
    >
      <div className="max-w-[1440px] mx-auto px-5 lg:px-20 flex justify-between items-center w-full flex-col sm:flex-row gap-3">
        <p className="text-sm text-white">
          This website uses cookies to ensure you get the best experience.{" "}
          <Link href="/cookies-policy" className="text-white">
            Learn more
          </Link>
        </p>

        <div className="flex flex-none items-center">
          <Button onClick={() => setCookieConsent(true)} type="button">
            Got it!
          </Button>
        </div>
      </div>
    </div>
  );
}
